body{
    background-color: #fff !important;
    scroll-behavior: smooth;
}
body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5, body.dark h6, body.dark p{
    color: var(--bodyText-color);
}
body.dark .btn-secondary:hover, body.dark .btn-secondary:focus, body.dark .btn-secondary{
    background-color: var(--bgNav-color) !important;
}
body.dark a{
    color: #515365;
}
body.dark .form-control:focus{
    box-shadow: none;
    border-color: var(--bgNav-color);
    color: var(--bgNav-color);
    background-color: #fff;
}
.react-confirm-alert-overlay{
    z-index: 9999999;
}
#content{
    width: 70%;
    margin-left: 0;
}
.main-container{
    padding: 0;
}
*{
    scroll-behavior: smooth;
}
.header-container .theme-brand{
    padding: 0;
}
.container-xxl{
    width: 100% !important;
    max-width: 100% !important;
}
.shadow-bottom{
    height: 0 !important;
}
.theme-logo{
    line-height: 1;
}
.theme-logo h3{
    margin: 0;
    font-family: initial;
    color: #fff;
}
.theme-logo small{
    color: #fff;
    font-family: initial;
    top: -10px;
    position: relative;
    letter-spacing: 4px;
    font-size: 10px;
}
.auth-overlay{
    background-image: none;
    background-color: #fff;
}
body.dark .auth-overlay{
    background-image: none;
    background-color: #fff;
}
body.dark .form-control{
    border: 1px solid #bfc9d4;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    padding: 0.75rem 1.25rem;
    border-radius: 6px;
    background: #fff;
    height: auto;
    scroll-behavior: smooth;
    transition: none;
}
body.dark label{
    font-size: 15px;
    color: #0e1726;
    letter-spacing: 1px;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.auth-cover img{
    height: auto !important;
    width: auto !important;
    transform: rotateZ(-45deg);
}
.custom_navbar{
    padding: 0 20px;
    margin: 0;
}
.custom_navbar li a{
    font-size: 15px;
    transition: .2s;
    color: #fff;
}
.custom_navbar li.active a{
    border-bottom: 2px solid #e0e6ed;
    transition: .2s;
}
.custom_navbar li{
    padding: 0 10px;
}
.custom_navbar .custom-dropdown-menu li{
    cursor: pointer;
    padding: 0 5px;
    font-size: 14px;
    font-weight: 600;
    color: #081a3e;
    text-transform: capitalize;
}
.custom_navbar .custom-dropdown-menu li:first-child:hover{
    background-color: #fff;
}
.custom_navbar .custom-dropdown-menu li:hover{
    background-color: #081a3e;
    transition: .2s;
    color: #fff;
}
.reports_sec .sidebar-wrapper {
    top: 50px;
}
.reports_sec #content{
    margin-top: 60px;
}
.cardFormCenter{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.cardFormStyle{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
}
.cardFormStyle h4{
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: center;
}
.cardFormStyle svg{
    display: block;
    margin: 0 auto 6px auto;
}
.password_input{
    position: relative;
}
.password_icon{
    position: absolute;
    right: 10px;
    top: 14px;
    cursor: pointer;
}
.password_strenght {
    padding: 0;
    list-style-type: none;
    margin: 0;
}
.password_strenght li span{
    font-size: 12px;
    display: flex;
    align-items: center;
}
.password_strenght li span svg{
    margin: 0;
    width: 15px;
    height: 12px;
}
.verify_sec{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.userStats_summary{
    margin-bottom: 20px;
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;
}
.infoBox{
    background-color: rgb(0,0,0,.8);
    padding: 10px 20px;
    text-align: center;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.info-box-text h6{
    font-size: 14px;
    color: #fff;
    padding-top: 10px;
    margin: 0;
}
.info-box-text p{
    color: #fff;
    font-size: 13px;
}
.user-info img{
    width: 120px;
    height: 120px;
    border-radius: 100% !important;
}
.profile_image_upload {
    width: 120px;
    margin: 0 auto;
    position: relative;
}
.profile_image_upload input{
    position: absolute;
    cursor: pointer;
    z-index: 999;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    left: 0;
    border-radius: 100%;
    opacity: 0;
}
.css-80pr5n-MuiPaper-root,.css-1hhu9xl{
    box-shadow: none !important;
}
.modal-content{
    background: #fff;
    padding: 20px;
    width: 100%;
    max-width: none;
}
.form-select, .form-control{
    padding: 10px !important;
    font-size: 14px !important;
}
.navbar .search-animated svg{
    pointer-events: auto;
    right: 0;
}
.header-container.container-xxl{
    left: 0 !important;
}
#sidebar *,#sidebar ul.menu-categories ul.submenu > li a{
    text-overflow: ellipsis;
}
#sidebar ul.menu-categories ul.submenu > li a{
    display: block;
    margin-left: 20px;
}
.loader_wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
}

.newLoader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.header-container .navbar.navbar-expand-sm .navbar-item .nav-item.theme-text{
    display: block !important;
}
.custom_dashboard{
    margin-top: 70px;
}
.widget.widget-card-five{
    height: 100%;
}
.personal_setting_sec .profile_image_upload{
    margin: 0;
}
.personal_setting_sec .user-info{
    display: flex;
    align-items: center;
}
.personal_setting_sec .profile_content{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}
.personal_setting_sec .profile_content sup{
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 12px;
    top: -15px;
}
.profile_content h5{
    font-size: 18px;
    font-weight: 600;
}
.personal_setting_sec{
    padding-left: 20px;
}
.personal_setting_sec table{
    width: 100%;
}
.personal_setting_sec table th,.personal_setting_sec table td{
    padding: 6px 10px;
}
.personal_setting_sec table td:first-child{
    width: 250px;
}
.personal_setting_sec .openToggle-div{
    padding: 10px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
}
.tabMenu ul{
    padding: 0;
    list-style-type: none;
    display: flex;
}
.tabMenu li{
    padding: 0 10px;
}
.tabMenu{
    border-bottom: 2px solid lightgrey;
}
.active_user_sec {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}
.active_user_sec .active_user_list,.active_user_sec .personal_setting_sec{
    width: 50%;
}
.active_user_list{
    border-right: 1px solid lightgrey;
    padding-right: 20px;
}
.active_user_list .user-info {
    display: flex;
    align-items: center;
    background-color: lightgrey;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
}
.active_user_list .profile_image_upload{
    margin: 0;
    width: 60px;
}
.active_user_list .user-info img{
    width: 60px;
    height: 60px;
}
.active_user_list .profile_content{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    line-height: 1;
}
.active_user_list .profile_content h5{
    font-size: 15px;
    margin: 0 0 4px 0;
    font-weight: 600;
}
.active_user_list .profile_content h5 sup{
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 12px;
    top: -15px;
}
.active_user_list .profile_content span{
    font-size: 13px;
}
.active_user_list select{
    width: 170px;
}
.statbox{
    margin-top: 20px;
}
.textUppercase{
    text-transform: uppercase;
}
.theme_setting{
    padding: 6px 10px;
}
.theme_setting h5{
    font-size: 18px;
    font-weight: 600;
    color: slategrey;
}
.theme_setting .color-item{
    background-color: var(--bgNav-color);
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin: 2px;
    border-radius: 50%;
}
.theme_setting .colors-list{
    display: flex;
    flex-wrap: wrap;
}
.treeViewData ul{
    list-style-type: none;
    padding: 0;
}
.customTable table{
    margin-top: 40px;
    width: 100%;
}
.customTable table th,.customTable table td{
    padding: 10px;
    border: 1px solid darkgrey;
    border-collapse: collapse;
}
.customTable .form-check label{
    margin-bottom: 0;
}
.customTable td:first-child{
    width: 25%;
}
.profile_setting_sidebar {
    margin-top: 130px;
    width: 20%;
    top: 0;
    position: sticky;
    padding-left: 20px;
    overflow-y: scroll;
    height: 500px;
    border-right: 1px solid lightgrey;
}
.profile_setting_sidebar ul{
    padding-left: 20px;
}
.profile_setting_sec ul{
    padding: 0;
    list-style-type: none;
}
.profile_setting_sec{
    width: 65%;
}
.profile_setting_sec li{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}
.profile_setting_sec button.ant-switch{
    margin-left: auto;
    margin-right: 20px;
}
.profile_setting_sec .msl-input,.profile_setting_sec .msl-chip,.profile_setting_sec .msl-single-value{
    font-size: 13px;
}
.profile_setting_sec .msl-grp-title,.profile_setting_sec .msl-options .msl-option{
    font-size: 13px;
    padding: 2px 5px;
}
.profile_setting_sec .msl-arrow-icn,.profile_setting_sec .msl-chip-delete{
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
}
.profile_setting_rightSide{
    padding-top: 6px;
}
.profile_setting_btns{
    position: fixed;
    right: 20px;
    top: 62px;
    z-index: 9999;
}
.profile_setting_btns .btn{
    margin: 0 4px;
}
.custom_tabs .react-tabs ul{
    padding: 0;
    list-style-type: none;
    display: flex;
    border-bottom: 2px solid lightgrey;
    padding-bottom: 18px
}
.custom_tabs .react-tabs ul li{
    margin-right: 4px;
}
.custom_tabs .react-tabs__tab--selected{
    color: #fff !important;
    background-color: #805dca;
    border-color: #805dca;
    box-shadow: 0 10px 20px -10px rgba(92, 26, 195, 0.59);
}
.custom_tabs .btn{
    padding: 6px 12px;
}
.modules_btn button{
    margin-left: 10px;
}
.dragNdrop-moduleList {
    height: 250px;
    overflow-y: auto;
    border: 1px solid lightgrey;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.dragNdrop-moduleList .card{
    box-shadow: none;
}
.moduleSideBar{
    margin-top: 130px;
    position: fixed;
    overflow-y: scroll;
    border-right: 1px solid lightgrey;
    height: 500px;
}
.moduleSideBar h5{
    font-size: 20px;
}
.moduleSideBar h5 svg{
    margin-top: -4px;
}
.moduleSideBar li{
    padding: 4px 0;
}
.moduleSideBar li a{
    font-size: 15px;
}
.viewModule_rightSIde h5{
    font-size: 24px;
}
.custom-search button{
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    background: transparent;
}
.colors-list{
    display: flex;
    flex-wrap: wrap;
    /*display: grid;
     grid-template-columns: auto auto auto auto;*/
    grid-gap: 10px;
    background: #fff;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
:root{
    --bgNav-color: #081a3e;
    --bodyBg-color: #fff;
    --bodyText-color: #0e1726;
}

.color-item{
    width: 30px;
    border: 2px solid grey;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
}
.navBgColor .color-item{
    background-color: var(--bgNav-color);
}
.bodyBgColor .color-item{
    background-color: var(--bodyBg-color);
}
.bodyTextColor .color-item{
    background-color: var(--bodyText-color);
}
h1, h2, h3, h4, h5, h6, p{
    color: var(--bodyText-color);
}
.header-container,.btn-secondary,.btn-secondary:hover, .btn-secondary:focus{
    background: var(--bgNav-color);
}
body{
    background-color: var(--bodyBg-color) !important;
}
.widget-content-area,.widget{
    border: 0;
    box-shadow: none;
}
.custom_dashboard .widget {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.personal_setting_sec td input{
    border: 0;
    border-bottom: 1px solid darkgrey;
}
.react-confirm-alert-body h1{
    font-size: 20px;
}
.form .react-tabs__tab-list{
    display: flex;
    padding: 0;
    list-style-type: none;
    margin-top: 10px;
}
.form .react-tabs__tab-list li{
    padding: 0.4375rem 1.25rem;
    text-shadow: none;
    font-size: 14px;
    color: #3b3f5c;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: 0.2s ease-out;
    touch-action: manipulation;
    border-radius: 6px;
    cursor: pointer;
    background-color: #e0e6ed;
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    margin-right: 10px;
}
.form .react-tabs__tab-list li.react-tabs__tab--selected{
    background: var(--bgNav-color);
    color: #fff;
}
.changeRoleBtn{
    padding: 2px;
    margin-left: 5px;
}
.changeRoleBtn svg{
    height: 14px;
    width: 24px;
}
#content.reportDetail{
    margin-left: 0 !important;
    width: 100% !important;
}
.filterSidebar .react-tabs__tab-list{
    list-style-type: none;
    display: flex;
    position: sticky;
    background-color: #fff;
    z-index: 9999;
    width: 100%;
    top: -20px;
    padding: 20px 0;
}
.filterSidebar .react-tabs__tab-list li{
    border: 1px solid #000;
    border-radius: 8px;
    padding: 4px 10px;
    cursor: pointer;
    font-weight: 700;
    margin-right: 10px;
}
.filterSidebar .react-tabs__tab-list li.react-tabs__tab--selected{
    background-color: #000;
    color: #fff;
    transition: .2s;
}
.filterSidebar{
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-right: 20px;
    top: 70px;
    position: relative;
    border-radius: 6px;
    overflow-y: scroll;
    height: 400px;
}
.filterSidebar .card{
    box-shadow: none;
}
.moduleSummaryTable{
    margin-top: 20px;
}
.moduleSummaryTable table{
    width: 100%;
}
.moduleSummaryTable tr td:first-child{
    width: 200px;
    text-align: end;
    color: #888ea8;
    padding-right: 10px;
}
.moduleSummaryTable tr td{
    color: #000;
}
.relatedModule{
    margin-top: 40px;
}
.inputModule{
    width: 30%;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 6px;
}
.inputModule input{
    color: #000 !important;
    opacity: .6 !important;
    width: 50%;
}
.stateBox{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
}
.stateBox span{
    font-size: 20px;
}
.checkSearchBox{
    position: relative;
    display: flex;
}
.checkSearchBox button{
    position: absolute;
    right: 10px;
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0;
    top: 4px;
}
.checkSearchBox input.form-control{
    padding: 6px !important;
    font-size: 12px !important;
}
.btn svg{
    height: 17px;
    width: 17px;
}
.module_check_list span{
    font-size: 14px;
    font-weight: 600;
    padding-left: 6px;
}
.moduleRecordFilter{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    margin-right: 10px;
    position: relative;
    top: 70px;
    border-radius: 6px;
    height: 410px;
    overflow-y: auto;
}
.moduleFilterSec ul{
    padding: 0;
    list-style-type: none;
}
.moduleFilterSec li{
    cursor: pointer;
}
.moduleFilterSec li label{
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    cursor: pointer;
}
.module-record-detail img{
    width: 70px;
    margin: 0 10px;
    height: 70px;
    border: 2px solid darkgrey;
    border-radius: 8px;
}
.moduleRecordDetailBodySidebar{
    border-right: 1px solid lightgrey;
    padding: 20px;
    margin-right: 20px;
}
.moduleRecordDetailSidebarContent h5{
    font-size: 18px;
    font-weight: 600;
}
.moduleRecordDetailSidebarContent ul{
    list-style-type: none;
    padding: 0;
}
.moduleRecordDetailSidebarContent li{
    padding: 4px 0;
}
.moduleRecordDetailSidebarContent li a{
    font-size: 14px;
    font-weight: 600;
}
.moduleRecordDetailBodyRightSide{
    height: 450px;
    padding: 0 10px;
    overflow-y: scroll;
}
.moduleRecordDetailBodyRightSide .react-tabs ul{
    padding: 0;
    list-style-type: none;
    align-items: center;
}
.moduleRecordDetailBodyRightSide .react-tabs ul li{
    margin: 6px;
    border: 2px solid #000;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: .3s;
}
.moduleRecordDetailBodyRightSide .react-tabs ul li:first-child{
    margin-left: 0;
}
.moduleRecordDetailBodyRightSide .react-tabs__tab--selected{
    background-color: #000;
    color: #fff;
    padding: 4px 10px;
    border-radius: 20px;
    font-size: 14px;
    transition: .3s;
}
.tab_overview_sec{
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
}
.inputFields_row{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.inputFields_row label{
    width: 40%;
    text-align: right;
    padding-right: 40px;
    font-size: 14px;
}
.inputFields_row input{
    font-size: 14px;
    border: 1px solid transparent;
    width: 60%;
}
.inputFields_row input:hover{
    border: 1px solid #000;
}
.tab_panel h5{
    font-size: 16px;
    font-weight: 700;
}
.inputFields_row button{
    margin: 0 6px;
}
.inputFields_row textarea{
    resize: none;
}
.layout-tab .openToggle-div{
    padding-top: 0;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
    display: block;
    border-bottom: 1px solid grey;
    padding-bottom: 4px;
}
.btn-secondary:hover, .btn-secondary:focus,.btn.active,.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,.custom_tabs .react-tabs__tab--selected
{
    background-color: #fff !important;
    color: #081a3e !important;
    border-color: #081a3e !important;
}
.tabCheckboxList{
    border: 1px solid lightgrey;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 20px;
    height: 210px;
    overflow-y: scroll;
}
.tabCheckboxList > div{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 2px;
    margin-bottom: 4px;
    padding: 6px;
}
.tabCheckboxList > div label{
    padding-left: 8px;
    margin: 0;
}
.tabCheckboxList > div:last-child{
    border-bottom: 0;
}
.module-layout-navbar ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.module-layout-navbar{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
}
.moduleFormBuilderPage{
    margin-top: 60px;
}
.module-layout-navbar-btn button{
    margin: 0 2px;
}
.module-layout-navbar .dropdown button{
    background: transparent;
    border: 0;
}
.moduleFormBuilderPage .container-fluid{
    padding: 0 20px;
}
.moduleFormBuilderSec{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.moduleFormBuilderLeftSide{
    width: 25%;
    border-right: 1px solid lightgrey;
    padding-right: 20px;
}
.formBuilderMenu ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.formBuilderMenu ul li{
    width: 49%;
    background-color: #081a3e;
    color: #fff;
    text-align: center;
    padding: 4px;
    margin: 2px 0;
    cursor: pointer;
}
.moduleFormBuilderLeftSide h6{
    color: #081a3e;
    font-size: 16px;
    font-weight: 600;
}
.moduleFormBuilderRightSide{
    width: 75%;
    padding: 20px;
}
.module-layout-navbar li:first-child{
    width: 25%;
}
.module-layout-navbar li:nth-child(2){
    margin-right: auto;
    padding-left: 20px;
}
.moduleFormBuilderRightSide .tabMenu{
    border-bottom: 0;
    margin-bottom: 20px;
}
.custom_sidebar .css-dip3t8{
    background-color: #fff;
}
.custom_sidebar{
    margin-top: 110px;
}
.custom_sidebar nav > ul > li a span{
    font-size: 15px;
    font-weight: 500;
}
.reportSidebar{
    margin-top: 70px;
    padding-left: 20px;
}
.css-dip3t8{
    background-color: #fff;
}
.toggleBtn{
    text-align: end;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid lightgrey;
}
.main-content{
    width: 80%;
    margin-top: 48px;
}
.assignedAppSec{
    padding: 20px;
}
.assignedAppSec ul{
    padding: 0;
    display: flex;
    list-style-type: none;
    margin-bottom: 30px;
}
.assignedAppSec li{
    padding-right: 20px;
    font-size: 16px;
    cursor: pointer;
}
.assignedAppSec .react-tabs__tab--selected{
    font-weight: 700;
    color: #000;
}
.unassignedApps h5,.assignedApp h5{
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    background: #081a3e;
    color: #fff;
    padding: 10px;
}
.unassignedApps ul{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}
.unassignedApps li{
    border-bottom: 1px solid lightgrey;
    padding-bottom: 4px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.unassignedApps li:last-child{
    border-bottom: 0;
}
.unassignedApps li span{
    font-size: 14px;
    color: #081a3e;
}
.unassignedApps li small{
    display: block;
    font-size: 12px;
}
.unassignedApps,.assignedApp{
    width: 70%;
}
.unassignedApps li button{
    display: none;
}
.unassignedApps li:hover button{
    display: block;
}
.kbmone #content{
    margin-top: 30px;
}
.assignedApp ul{
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
}
.assignedApp li{
    width: 20%;
    text-align: center;
    border: 1px solid lightgrey;
    /*padding: 20px;*/
    border-radius: 4px;
    position: relative;
    margin-right: 20px;
    padding-right: 0;
    margin-bottom: 10px;
}
.assignedApp li a{
    display: block;
    height: 100%;
    width: 100%;
    padding: 20px;
}
.assignedApp li span{
    font-size: 14px;
    font-weight: 600;
    color: #081a3e;
}
.assignedApp li svg{
    position: absolute;
    top: 2px;
    right: 2px;
}
.moduleData .MuiPaper-root{
    overflow-x: auto;
}
.moduleData td{
    overflow: visible;
}
.moduleData .css-1vlry5a-MuiTableContainer-root{
    overflow-y: visible;
}
/*.moduleData{
    overflow-x: auto;
    overflow-y: hidden;
}*/
/*.moduleData td{
    position: relative;
}*/
.optPLeft {
    padding-left: 25px !important;
}
.ReactModalPortal .ReactModal__Overlay{
    z-index: 9999;
}
.customDropDownButton button,.customDropDownButton .btn-primary:hover,.customDropDownButton .btn-primary:focus{
    background-color: transparent !important;
    color: #000 !important;
    border: 0;
    box-shadow: none;
    font-weight: 600;
    padding: 0 8px;
}
.custom-dropdown-menu button,.custom-dropdown-menu .btn-primary:hover,.custom-dropdown-menu .btn-primary:focus{
    background-color: transparent !important;
    color: #fff !important;
    border: 0;
    box-shadow: none;
    padding: 0 8px;
    font-weight: 600;
}
.courseCurriculumBox{
    text-align: center;
    border-radius: 6px;
    padding: 20px;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.courseCurriculumBox h5{
    margin: 0;
    font-size: 18px;
}
.courseCurriculumBox img{
    height: 100px;
    margin-bottom: 10px;
}
.uploadCourseFiles{
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.courseCurriculumHeading,.courseCurriculumImportHeading{
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
}
.courseCurriculumImportSec{
    padding-top: 40px;
}
.customReactCheckTree li{
    padding: 12px;
    background: #fbfbfb;
    border: 1px solid lightgrey;
}
.customReactCheckTree ol ol li{
    border: 0;
}
.customReactCheckTree li:last-child{
    border-top: 0;
}
.customReactCheckTree .react-checkbox-tree label:hover{
    background-color: transparent;
}
.customReactCheckTree .rct-node-icon{
    display: none;
}
.courseAIModel label{
    font-size: 13px;
    display: block;
    margin: 0;
}
.courseAIModel span{
    font-size: 11px;
    line-height: 1.2;
    display: block;
}
.courseAIModel textarea{
    height: 100px;
    margin-top: 6px;
}
.courseAIModel h4{
    font-size: 20px;
}
.courseAIModel p{
    font-size: 13px;
    line-height: 1.2;
    padding-bottom: 10px;
}
.newSecPanel{
    /*background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
    padding: 20px;*/
    position: relative;
    width: 100%;
    padding-left: 10px;
}
.courseCurriculumSec .section{
    position: relative;
    background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
    padding: 20px;
    margin: 30px 0;
}
.courseCurriculumSec .section .fa-times{
    position: absolute;
    right: 10px;
    top: 10px;
}
.newSecPanelHead{
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 20px;
}
.newSecPanelDropDown button,.newSecPanelDropDown .btn-primary:hover, .newSecPanelDropDown .btn-primary:focus,.newSecPanelDropDown .show > .btn-primary.dropdown-toggle,
.lessonCOntentDropdown button,.lessonCOntentDropdown .btn-primary:hover,.lessonCOntentDropdown .btn-primary:focus,.lessonCOntentDropdown .show > .btn-primary.dropdown-toggle{
    background-color: transparent !important;
    border: 0;
    box-shadow: none;
    color: #000 !important;
}
.newSecPanelDropDown li,.lessonCOntentDropdown li{
    padding: 2px 12px;
    cursor: pointer;
    font-size: 12px;
    color: #000;
}
.newSecPanelDropDown li span{
    white-space: nowrap;
}
.newSecPanelDropDown li a{
    color: #000;
}
.newSecPanelDropDown li:last-child{
    color: red;
}
.newSecPanelBottom ul{
    padding: 0;
    margin: 0;
    display: flex;
    list-style-type: none;
}
.newSecPanelBottom li{
    padding-right: 20px;
}
.newSecPanelBottom li i{
    color: #000;
    font-size: 16px;
    padding-right: 4px;
}
.newSecPanelBottom li span{
    color: #000;
    cursor: pointer;
    font-size: 16px;
}
.addPanelSec{
    padding: 20px;
    background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
    cursor: pointer;
    margin-top: 20px;
}
.addPanelSec span,.addPanelSec i{
    color: #000;
    font-size: 18px;
}
.addPanelSec i{
    padding-right: 4px;
}
.newLessonPanel{
    margin-bottom: 30px;
    padding-left: 10px;
    width: 100%;
}
.newLessonPanel h6{
    text-decoration: underline;
    margin-bottom: -20px;
}
.customDocViewer #pdf-download{
    display: none;
}
.lessonCOntent,.addContentOption{
    padding: 20px;
    background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
}
.lessonCOntentHead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.addContentOption h4{
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.lessonCOntentHead button{
    margin-left: auto !important;
}
.lessonContentBody{
    text-align: center;
    padding: 30px 0;
}
.addContentOption ul{
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.addContentOption li i{
    display: block;
    font-size: 20px;
    margin-bottom: 6px;
}
.addContentOption li{
    border: 1px solid #e4e4e4;
    width: 48%;
    text-align: center;
    margin-bottom: 10px;
    padding: 20px 0;
    cursor: pointer;
}
.tox-statusbar__right-container{
    display: none !important;
}
.curriculumSetupPanel{
    background-color: #fbfbfb;
    border: 1px solid #e4e4e4;
    padding: 20px;
    margin-top: 20px;
}
.curriculumPanelList {
    background-color: #ffff;
    border: 1px solid #e4e4e4;
    height: 350px;
    overflow-x: auto;
    margin-top: 20px;
}
.curriculumPanelList ul{
    padding: 0 20px;
    margin: 0;
    list-style-type: none;
}
.curriculumPanelList ul li{
    padding: 5px 0;
}
.curriculumPanelList ul li span,.curriculumPanelList h6{
    color: #000;
    font-weight: 600;
}
.curriculumPanelList ul li small{
    display: block;
}
.curriculumPanelList h6{
    background-color: #fbfbfb;
    padding: 20px;
    border-bottom: 1px solid #e4e4e4;
}
.pricingPanel{
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pricingPanel span{
    color: #000;
}
.pricingPanel .fa-clone{
    cursor: pointer;
}
.titlePanel {
    margin-top: 20px;
}
.titlePanel input{
    width: 100%;
    border: 1px solid #0000;
    background: transparent;
    text-transform: capitalize;
}
.titlePanel input:hover{
    border: 1px solid #000;
}
.titlePanel span{
    color: #000;
}
.titlePanel .profile_image_upload{
    width: auto;
}
.skillsPanel .multi-select{
    width: 100%;
}
.multi-select-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.multi-select-container.show {
    opacity: 1;
}
.quizHeadBtn{
    padding: 0;
    list-style-type: none;
    margin: 0;
    display: flex;
}
.quizSec{
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}
.quizSec,.addQuiz{
    background-color: #fbfbfb;
    padding: 20px;
    border: 1px solid #e4e4e4;
}
.dndQuizSec{
    width: 100%;
}
.quizQuestion{
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
}
.quizAnswer{
    padding-top: 20px;
}
.quizAnswerInput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
}
.quizAnswerInput .form-check-input{
    margin: 0 20px;
    border-color: #aaaaaa;
}
.addQuiz{
    margin-top: 20px;
    cursor: pointer;
}
.addQuiz span{
    font-size: 16px;
    color: #0e1726;
}
.addQuiz i{
    font-size: 18px;
    color: #0e1726;
    padding-right: 4px;
}
.addAnswer{
    padding: 10px 0;
    cursor: pointer;
}
.addAnswer span{
    font-size: 16px;
    color: #0e1726;
}
.addAnswer i{
    font-size: 18px;
    color: #0e1726;
    padding-right: 4px;
}
.dndBtn{
    border: 0;
    background: transparent;
    cursor: all-scroll !important;
}

#calendarDetailForm {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}
#calendarDetailForm.show {
    opacity: 1;
    max-height: 100%;
}
.hide {
    display: none;
}
.day-checkbox {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
    background-color: #ccc;
    cursor: pointer;
}

.day-checkbox input[type="checkbox"] {
    display: none;
}

.day-checkbox input[type="checkbox"] + label {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.day-checkbox input[type="checkbox"]:checked + label {
    background-color: #0c92ff;
    border-radius: 50%;
}

.day-checkbox input[type="checkbox"] + label::before {
    content: attr(data-day);
    font-size: 13px;
    color: white;
}
.multipleBookingCheck{
    display: flex;
    align-items: baseline;
}
.multipleBookingCheck label{
    margin-left: 6px;
    white-space: nowrap;
}
.active-menu-item > a{
    background-color: var(--bgNav-color) !important;
    color: #fff !important;
    margin-bottom: 1px;
}
.secPanelTitle{
    padding: 0;
    list-style-type: none;
}
.secPanelTitle h5{
    font-size: 18px;
}
.secPanelTitle h6{
    font-size: 16px;
}