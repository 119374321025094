.header {
  font-weight: bold !important;
  text-align: center;
}

.day-body {
  font-weight: bold !important;
  text-align: center;
  height:'100px' !important;
}

.more {
  margin: 0px;
  border-radius: 100px;
  background-color: #f1f1f1;
}

.more:hover {
  color: #ffffff;
  background-color: #ababab;
  cursor: pointer;
}

.selected-more {
  color: #ffffff;
  background-color: #ababab;
}

.more-list {
  width: 150px;
  padding: 10px;
}

.more-list > div {
  padding: 5px;
  border-radius:3px;
  cursor: pointer;
}
.more-list > div:hover {
  color: #000000;
  /* font-weight: bold; */
  background-color: #f1f1f1;
}

.modal-medium {
  color: #000000;
  position: fixed;
  width: 400px;
  height: 550px;
  top: 70px;
  left: calc(50% - 200px);
  display: none;
  padding: 10px;
  border-radius: 10px;
}
.modal-sm-auto-height {
  color: #000000;
  position: fixed;
  width: 400px;
  height: auto;
  top: 70px;
  left: calc(50% - 200px);
  display: none;
  padding: 10px;
  border-radius: 10px;
}

.modal-title {
  font-size: 20px;
  font-weight: bold;
}

.modal-date {
  font-size: 17px;
  font-weight: bold;
}

.close-btn {
  font-size: 35px;
  /* font-weight: bold; */
  line-height: 20px;
  border-radius: 100px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-btn:hover {
  background-color: #f1f1f1;
}

.modal-hr {
  margin: 10px 0px;
}

.event {
  width: 100%;
  height: 20px;
  margin-bottom: 3px;
  border-radius:3px;
  overflow: hidden;
}

.green {
  background-color: #02f80a80;
}
.yellow {
  background-color: #f8f00280;
}
.red {
  background-color: #f8020280;
}
.grey {
  background-color: #c7c7c780;
}