:root {
  --blue: #0e88f3;
  --ddbg: #0e88f350;
}

.center {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.p5 {
  padding: 5px;
}

.elm-box {
  width: 100%;
  /* width: 300px; */
  height: 40px;
  color: grey;
  border: 1px solid #d2d9df;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  cursor: grab;
}
.elm-box:active {
  cursor: grabbing;
}

.nullInput {
  border: 1px solid red !important;
}

.elm-static-box {
  width: 370px;
  height: 40px;
  color: grey;
  border: 1px solid #ffd11b;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  background-color: #fcf8e6;
  display: flex;
  align-items: center;
  opacity: .8;
}

.multi-line {
  height: 80px;
  align-items: flex-start;
}

.new-selection {
  width: 500px;
  height: 150px;
  border-radius: 0px;
  align-items: flex-start;
  flex-direction: column;
}

.section-header {
  display: flex;
  justify-content: space-between;
  cursor: move;
}

.section-settings-icon {
  cursor: default;
  width: 28px;
  height: 31px;
  padding: 5px;
  color:#848484
}

.section-settings-icon:hover {
  cursor: pointer;
  width: 28px;
  height: 31px;
  color:#000000;
}

.multi-line {
  height: 80px;
  align-items: flex-start;
}

.checkmark {
  color: var(--blue);
  font-size: 15px;
  line-height: 14px;
  margin-right: 5px;
}

.elm-drop-title {
  font-size: 12px;
  font-weight: 450;
  padding: 4px 10px;
}

.elm-drop-item {
  display: flex;
  font-size: 13px;
  cursor: pointer;
  padding: 4px 10px;
}

.elm-drop-line {
  margin: 8px 0px 5px;
}

.elm-drop-item:hover {
  background-color:lightgray;
}

.elm-section {
  width: 100%;
  border: 1px dashed #bdc4ca;
  padding: 5px;
  margin-bottom: 10px;
  background-color:#00000015;
}

.elm-section-input {
  width: 200px;
  height: 30px;
  border-style: none;
  border-radius: 0px;
  background-color:#ffffff;
  font-size: 14px;
}

.elm-section-box {
  display: flex;
  width: 100%;
  min-height: 130px;
  border-style: none;
  padding: 10px 0px 0px;
}

.elm-section-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
  background-color:#ffffff;
  padding: 0px 5px 30px;
}

.select-section-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
  align-items: stretch;
  background-color:#ffffff;
}

.option-box {
  width: 100%;
  height: 400px;
  border-radius: 3px;
  margin: 0px 10px 0px 0px;
  padding: 10px;
  background-color:  #F6F6F6;
  border: 1px solid #e4e4e4;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: scroll;
}

.obj-box {
  min-width: 310px;
  max-width: 310px;
  /* height: 100%; */
  border-radius: 10px;
  margin: 0px 10px 0px 0px;
  padding: 10px;
  background-color:  #000000;
  border: 1px solid #999999;
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-wrap: wrap;
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.obj-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.obj-box {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.fields-box {
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-wrap: wrap;
}

.form-box {
  width: 100%;
  /* height: 700px; */
  border-radius: 10px;
  margin: 10px 0px 0px;
  padding: 10px 10px 100px;
  background-color:  #00000009;
  border: 1px solid #999999;
  overflow-y: scroll;
}
.form-box::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.form-box {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.form-title {
  margin: 10px 0px 0px;
}

.elm-placement {
  width: 100%;
  height: 40px;
  border: 1px dashed var(--blue);
  background-color: var(--ddbg);
  margin-bottom: 5px;
  border-radius: 5px;
}

.section-placement {
  width: 100%;
  height: 150px;
  border: 1px dashed var(--blue);
  background-color: var(--ddbg);
  margin-bottom: 10px;
}

.option-placement {
  /* width: 100%; */
  height: 30px;
  min-height: 30px;
  border: 1px dashed var(--blue);
  background-color: var(--ddbg);
  margin-bottom: 10px;
  border-radius: 3px;
}

.option-input {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border-radius: 3px;
  padding-left: 25px !important;
}

.elm-input {
  width: 150px;
  height: 27px;
  color: #99652C;
  font-size: 13px;
}

.elm-static-input {
  width: 300px;
  height: 27px;
  border: 1px solid #bc8449;
  border-radius: 3px;
  color: #99652C;
  font-size: 13px;
  padding-top: 3px;
}

.select-static-input {
  width: 150px;
  height: 27px;
  border: 1px solid #bc8449;
  border-radius: 0px;
  color: #99652C;
  font-size: 13px;
  padding-top: 3px;
  margin-bottom: 5px;
}

.elm-label {
  width: 100%;
  height: 27px;
  text-align: left;
  padding: 0px 10px;
  line-height: 27px;
}

.checkbox-label {
  width: 18px;
  height: 18px;
  border: 1px solid;
  border-radius: 2px;
}

.elm-more {
  width: 18px;
  height: 27px;
  cursor: pointer;
}

.grabbable {
  position: absolute;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.static {
  width: 133px;
  height: 35px;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  margin: 5px;
  align-items: center;
  cursor: grab;
  background-color: #99000f;
  color: #ffffff;
}

.static:hover {
  background-color: #fff5cb;
  color: #000000;
}

.w100p {
  width: 100%;
}

.section-child{ 
  background-color: #ffffff00;
}
.section-parent:hover .section-child{
  background-color: #ffffff;
}

.input-child{ 
  background-color: #ffffff00;
  border-color: #e9e9e9;
}
.input-parent:hover .input-child{
  border-color: #99652C;
}

.font-red {
  color: red
}

.close-cross {
  width: 20px;
  font-size: 25px;
  cursor: pointer;
}

.mt20 {
  margin-top: 20px;
}

.section-title {
  font-size: 15px;
  font-weight: bold;
  padding: 10px 0px 20px;
}
.elm-title {
  color: #65748c;
  font-size: 15px;
  text-align: right;
  width: 150px;
  padding: 3px 0px 0px;
}

.form-control {
  border-color: #aaaaaa;
}

.form-control:hover {
  border-color: #000000;
}

.form-control:focus{
  border-color: #1376ff;
  box-shadow: 1 1 1 0.1rem #5f93fb;
}

.hover {
  opacity: .4;
}

.hover:hover {
  opacity: 1;
  cursor: pointer;
}

.blue-hover:hover {
  color: #008DF8;
}

.red-hover:hover {
  color: red;
}

.dropdown-menu {
  font-size: 14px;
}

.optionBtn {
  height: 30px;
  width: 26px;
  margin-left: 8px;
  cursor: pointer;
}

.option-child{
  visibility: hidden;
}
.option-parent:hover .option-child{
  visibility: visible;
}

.obj-child{
  visibility: hidden;
}
.obj-parent:hover .obj-child{
  visibility: visible;
}

.selected-option {
  margin: 1px;
  padding: 1px 2px;
  border: 1px solid #5f93fb;
  background-color:#e6edf9;
  border-radius: 100px;
  display: flex;
  align-items: center;
}

.option-remove {
  border: 1px solid #5f93fb;
  background-color:#8eb2f5;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#ffffff;
  cursor: pointer;
  font-size: 16px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: pointer;
  display: block;
}

.div-element {
  position: relative;
  background-color: #F7F7F7;
  border-radius: 5px;
  border: 1px solid #99999999;
  overflow: hidden;
  margin: 3px 3px 6px;
  width: 48%;
}

.img-element {
  object-fit: cover;
  width: 32px;
  min-width: 32px;
  height: 32px;
}

.download-icon {
  font-size: 16px;
  margin: 4px 15px 0px 0px;
}

.name-element {
  align-items: center;
  height: 32px;
  width: 100%;
  padding-left: 10px;
  overflow: scroll;
  white-space: nowrap;
}

.sec-element {
  align-items: center;
  position: absolute;
  height: 32px;
  background-color: #e2e2e2;
  border-left: 2px solid #444444;
  right: 0;
  cursor: pointer;
}

.trash-icon {
  object-fit: contain;
  font-size: 16px;
}

.select-item {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  /* user-select: none; */
}

.select-item:hover {
  background-color:#f4f5f6
}


.disable-select {
  user-select: none;
 -webkit-user-select: none;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
}

.btnShadowX {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin: 5px;
  color: #000000;
  transition: all .5s ease;
}
.btnShadowX:hover {
  margin: 0px 5px 10px 5px ;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 55px -17px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 55px -17px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 55px -17px rgba(0,0,0,0.75);
  transition: all 0.1s ease;
}
.btnShadowX:active {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  margin: 5px;
  transition: all 0.1s ease;
}

.table-hover tbody tr td {
  background: #ffffff;
}
.table-hover tbody tr:hover td {
  background: #f5f5f5;
}

.cardShadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
}

.pointer {
  cursor: pointer;
}
