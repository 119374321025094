.table-more {
  background-color: transparent;
  font-size: 20px;
  margin: 2px 0px 0px;
  border-radius: 100px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-more:hover {
  background-color: #BFC9D480;
}

.hoverGrey {
  font-size: 20px;
  padding: 10px;
  margin: 5px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s ease;
}
.hoverGrey:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: all 0.1s ease;
}

.column-list-header {
  width: 100%;
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid #99999930;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.column-list-header > div {
  padding: 5px 7px;
  border-radius:5px;
  color: #1A76D2;
  font-weight: 900;
  cursor: pointer;
  transition: all .5s ease;
}
.column-list-header > div:hover {
  border-radius:5px;
  background-color: #f0f7fe;
  transition: all 0.3s ease;
}

.column-list {
  width: 250px;
  padding: 0px;
}

.column-list > div {
  padding: 5px;
  border-radius:3px;
  cursor: pointer;
}
.column-list > div:hover {
  color: #000000;
  /* font-weight: bold; */
  background-color: #f1f1f1;
}

.table-more-list {
  width: 150px;
  padding: 10px;
}

.table-more-list > div {
  padding: 5px;
  border-radius:3px;
  cursor: pointer;
}
.table-more-list > div:hover {
  color: #000000;
  /* font-weight: bold; */
  background-color: #f1f1f1;
}

.hoverGreyColumnList {
  transition: all 0.3s ease;
}

.hoverGreyColumnList:hover {
  cursor: pointer;
  background-color: #f1f1f1;
  transition: all 0.1s ease;
}

.column-menu-btn {
  width: 27px;
  height: 27px;
  font-size: 17px;
  color:#999999;
  border-radius: 100px;
  transition: all 0.1s ease;
}

.column-menu-btn:hover {
  color:#000000;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.column-sort-btn {
  width: 25px;
  height: 25px;
  color:#99999900;
  transition: all 0.1s ease;
}

.column-sort-btn:hover {
  color:#999999;
  cursor: pointer;
  transition: all 0.3s ease;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.checkboxX {
  margin-top: 0px !important;
}